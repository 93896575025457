import { lazy } from "react";
// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
const Login = lazy(() => import("../pages/Authentication/Login"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const Register = lazy(() => import("../pages/Authentication/Register"));
const ForgetPwd = lazy(() => import("../pages/Authentication/ForgetPassword"));
const Overview = lazy(() => import("../pages/Overview/index"));
const Overview2 = lazy(() => import("../pages/Overview/index2"));
const Outcomes = lazy(() => import("../pages/Outcomes/index"));
const MOCProgression = lazy(() => import("../pages/MOCProgression/index2"));
const MOCProgression2 = lazy(() => import("../pages/MOCProgression/index2"));
const CaseFinding = lazy(() => import("../pages/CaseFinding/index"));
const DiseaseAreas = lazy(() => import("../pages/DiseaseAreas/index"));
const Payments = lazy(() => import("../pages/Payments/index"));
const ThirdEmpire = lazy(() => import("../pages/ThirdEmpire/index"));
const Knowledge = lazy(() => import("../pages/Knowledge/index"));
const Feedback = lazy(() => import("../pages/Feedback/index"))
const DataQuality = lazy(() => import("../pages/DataQuality/index"))


const UserManagement = lazy(() => import("../pages/UserManagement/index"));
const RoleManagement = lazy(() => import("../pages/RoleManagement/index"));
const AddRole = lazy(() => import("pages/RoleManagement/AddRole"));

const Faq = lazy(() => import("../pages/Faq/index"));
const Import = lazy(() => import("../pages/Import/index2"));
const Oneview = lazy(() => import("../pages/Oneview/index"));
const PanOneview = lazy(() => import("../pages/PanOneView/index"));
const Undermaintenance = lazy(() => import("../pages/Authentication/under_maintenance"));

let authProtectedRoutesUser = [];
let authProtectedRoutesAdmin = [];
let authProtectedRoutesApprover = []

authProtectedRoutesUser = [
  { path: "/profile", component: UserProfile, exact: true },
  { path: "/faq", component: Faq, exact: true },
  { path: "/", exact: true, component: Oneview },
  { path: "/pi-one-view", exact: true, component: PanOneview },
  // { path: "/overview", exact: true, component: Overview },
  { path: "/overview", exact: true, component: Overview2 },
  { path: "/outcomes", component: Outcomes, exact: true },
  // { path: "/moc-progression", component: MOCProgression, exact: true },
  { path: "/moc-progression", component: MOCProgression2, exact: true },
  { path: "/case-finding", component: CaseFinding, exact: true },
  { path: "/disease-areas", component: DiseaseAreas, exact: true },
  { path: "/payments", component: Payments, exact: true },
  { path: "/eoy-predictor", component: ThirdEmpire, exact: true },
  { path: "/knowledge", component: Knowledge, exact: true },
  { path: "/data-quality", component: DataQuality, exact: true },
  { path: "/feedback", component: Feedback, exact: true },
  { path: "/one-view", component: Oneview, exact: true },
];

authProtectedRoutesAdmin = [
  ...authProtectedRoutesUser,
  {
    path: "/user-management",
    component: UserManagement,
    exact: true,
  },
  {
    path: "/role-management",
    component: RoleManagement,
    exact: true,
  },
  {
    path: "/add-role",
    component: AddRole,
    exact: true,
  },
  {
    path: "/edit-role",
    component: AddRole,
    exact: true,
  },
  {
    path: "/import",
    component: Import,
    exact: true,
  }
];

authProtectedRoutesApprover = [
  ...authProtectedRoutesUser,
  {
    path: "/user-approval",
    component: UserManagement,
    exact: true,
  },
];

const publicRoutes = [
  { path: "/", component: Logout, exact: true },
  { path: "/login", component: Login, exact: true },
  { path: "/forgot-password", component: ForgetPwd, exact: true },
  { path: "/reset-password", component: ForgetPwd, exact: true },
  { path: "/register-email", component: Register, exact: true },
  { path: "/register-password", component: Register, exact: true },
  { path: "/register-authcode", component: Register, exact: true },
  { path: "/update-profile", component: Register, exact: true },
  { path: "/termsconditions", component: Register, exact: true },
  { path: "/setup2fa", component: Register, exact: true },
  { path: "/under-maintenance", component: Undermaintenance, exact: true },
];

export { publicRoutes, authProtectedRoutesUser, authProtectedRoutesAdmin, authProtectedRoutesApprover };
